import React, { Suspense, useContext, useEffect } from 'react';
import { withWidth } from '@material-ui/core';
import { DialogProvider, LoadingBounce } from '@michelin/acid-components';
import {
	APPS,
	CentralProvider,
	CentralProviderContext,
	Languages,
	THEMES,
	centrali18n,
	initReactI18next,
} from '@michelin/central-provider';
import {
	initMaestroTranslations,
	prefixedTranslations,
	sharedTranslations,
} from '@michelin/maestro-common-translations';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import { GUEST_ROUTES } from 'routes/Routes';

const Routes = React.lazy(() => import('routes/Routes'));
export const history = createBrowserHistory();

const QUERY_CLIENT_CONFIG = {
	defaultOptions: {
		queries: {
			staleTime: 10000,
		},
	},
};

initMaestroTranslations(
	centrali18n,
	initReactI18next,
	{
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		en_US: {
			// eslint-disable-next-line global-require
			...require('../i18n/dynamic_en_US.json'),
			// eslint-disable-next-line global-require
			...require('../i18n/static_en_US.json'),
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		fr_CA: {
			// eslint-disable-next-line global-require
			...require('../i18n/dynamic_fr_CA.json'),
			// eslint-disable-next-line global-require
			...require('../i18n/static_fr_CA.json'),
		},
	},
	[sharedTranslations.dates, prefixedTranslations(sharedTranslations.country_codes, 'COUNTRY_CODE|')],
);

function RenderApp() {
	const { setCurrentTheme, currentTheme } = useContext(CentralProviderContext);
	useEffect(() => setCurrentTheme('michelinLightBC'), [setCurrentTheme, currentTheme]);
	return (
		<DialogProvider>
			<SnackbarProvider>
				<Router history={history}>
					<Routes />
				</Router>
			</SnackbarProvider>
		</DialogProvider>
	);
}

function App() {
	return (
		<CentralProvider
			redirect="/"
			application={APPS.CENTRAL}
			languages={[Languages.EN_US, Languages.FR_CA]}
			redirectToPreviousRoute
			useCentralProviderDebugger={false}
			useTranslationDebugger={false}
			onLogin={(route) => {
				history.push(route);
			}}
			themes={{
				on: THEMES.michelinLightBC,
			}}
			prepareQueryClientConfig={() => QUERY_CLIENT_CONFIG}
			allowGrantLocationSelector={false}
			showGlobalSearch={false}
			allowedGuestRoutes={GUEST_ROUTES}
			disableTranslationsManager
			devexLightThemeCSS={`${process.env.PUBLIC_URL}/css/dx.material.brand-center.css`}
			devexDarkThemeCSS={`${process.env.PUBLIC_URL}/css/dx.material.brand-center.css`}
		>
			<Suspense fallback={<LoadingBounce style={{ height: '90vh' }} />}>
				<RenderApp />
			</Suspense>
		</CentralProvider>
	);
}

export default withWidth()(App);
